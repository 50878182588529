<template>
    <modal ref="modalAprobarAnuncio2" titulo="Editar anuncio" :cargando="loading" adicional="guardar" tamano="modal-md" @adicional="guardarValores">
        <div class="container">
            <ValidationObserver ref="form" tag="div">
                <div class="row mx-0 mt-3">
                    <div class="col-12">
                        <p class="f-15">Tipo de anuncio</p>
                        <div class="d-flex mt-3">
                            <div class="wh-32px rounded-circle d-middle-center my-auto" :style="`background:${categoriaSelected.color}`">
                                <i :class="`${categoriaSelected.icono} op-05 f-20`" />
                            </div>
                            <div class="ml-2 flex-fill">
                                <ValidationProvider v-slot="{errors}" rules="required" name="categoría">
                                    <el-select v-model="cartelera.id_categoria" placeholder="Seleccionar tipo" size="small" class="w-100" @change="setCategoriaSelected">
                                        <el-option v-for="(cat,index) in categorias" :key="index" :label="cat.nombre" :value="cat.id" />
                                    </el-select>
                                    <p class="text-danger f-10"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                            <div v-if="categoriaSelected.dinero" class="ml-2">
                                <ValidationProvider v-slot="{errors}" rules="required|numeric|min_value:1|max_value:16777215" name="dinero">
                                    <el-input v-model="cartelera.dinero" placeholder="Escriba un valor" size="small" />
                                    <p class="text-danger f-10"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <p class="col-12 f-15">Indicar la vigencia del anuncio</p>
                    <div class="col mt-3">
                        <p class="f-12 label-inputs text-left">Fecha de inicio</p>
                        <ValidationProvider v-slot="{errors}" rules="required" name="fecha">
                            <el-date-picker v-model="cartelera.vigencia_inicio" type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" format="dd [de] MMMM yyyy" placeholder="Selecciona la fecha" class="w-100" size="small" />
                            <p class="text-danger f-10"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="col mt-3">
                        <p class="f-12 label-inputs text-left">Duración (días)</p>
                        <ValidationProvider v-slot="{errors}" rules="required|numeric|min_value:1|max_value:100" name="duración">
                            <el-input v-model="duracion" placeholder="Número de días" class="w-100" size="small" />
                            <p class="text-danger f-10"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-12 col-lg-auto d-flex">
                        <i :class="`icon-pin mr-2 cr-pointer f-17 ${cartelera.top ? 'text-blue' : 'text-general'}`" @click="fijarAnuncio" />
                        <p class="f-14">Fijar el anuncio</p>
                    </div>
                    <div class="col-12 col-lg-auto d-flex el-checkbox-negro">
                        <el-checkbox v-model="cartelera.destacado" :true-label="1" :false-label="0">
                            Destacar el anuncio
                        </el-checkbox>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </modal>
</template>
<script>

import moment from 'moment';
import Carteleras from '~/services/carteleras';
export default {
    data(){
        return{
            loading: false,
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'d');
                },
            },
            duracion:0,
            categorias: [],
            cartelera:{
                id: null,
                id_categoria : null,
                dinero: 0,
                vigencia_inicio: null,
                vigencia_fin: null,
                top: 0,
                destacado: 0
            },
            categoriaSelected: {}

        }
    },
    methods:{
        toggle(cartelera,categorias){
            this.cartelera = {}
            this.categorias = categorias
            this.cartelera = cartelera
            this.categoriaSelected = this.categorias.find(el=> el.id == this.cartelera.id_categoria)
            this.diferenciaDias()
            this.$refs.modalAprobarAnuncio2.toggle()
        },
        async guardarValores(){
            try {
                let validate = await this.$refs.form.validate()
                if(!validate) return this.notificacion('','Por favor completa todos los campos')
    
                this.cartelera.vigencia_fin = moment(this.cartelera.vigencia_inicio).add(this.duracion, 'days').format('YYYY-MM-DD')
                this.cartelera.vigencia_inicio = moment(this.cartelera.vigencia_inicio).format('YYYY-MM-DD')
    
                let payload= {
                    id_cartelera : this.cartelera.id,
                    vigencia_inicio: this.cartelera.vigencia_inicio,
                    vigencia_fin: this.cartelera.vigencia_fin,
                    dinero: this.cartelera.dinero,
                    id_categoria: this.cartelera.id_categoria,
                    top: this.cartelera.top,
                    destacado: (this.cartelera.destacado) ? 1 : 0
                }
    
                const {data} = await Carteleras.guardar(payload)
    
                this.notificacion('','Anuncio guardado correctamente','success')
                this.$emit('update')
    
                this.$refs.modalAprobarAnuncio2.toggle()
            } catch (error){
                this.errorCatch(error)
            }
        },
        fijarAnuncio(){
            this.cartelera.top = this.cartelera.top ? 0 : 1; 
        },
        setCategoriaSelected(){
            this.categoriaSelected = this.categorias.find(el=> el.id == this.cartelera.id_categoria)
            this.cartelera.dinero = null
        },
        diferenciaDias(){
            let inicio = moment(this.cartelera.vigencia_inicio)
            let fin = moment(this.cartelera.vigencia_fin)
            this.duracion = fin.diff(inicio,'days')
        }
    }
}
</script>
<style lang="scss" scoped>

</style>